import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faSpotify,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import NextLink from "next/link";
import * as config from "../../../config/config";
import { setEmployerParams } from "../../../helpers/multicountry_management";
import {
  ColumnContainer,
  ColumnContainerMiddle,
  ColumnLink,
  CompanyTitleStyled,
  ContainerStyled,
  ContentContainer,
  CopyrightContainer,
  CopyrightContainerMiddle,
  CopyrightText,
  DownloadAppLogo,
  FirstContentContainer,
  GridIcons,
  GridIconsContainer,
  GridLinks,
  GridLinksContainer,
  GridMission,
  IconStyled,
  LinkExternal,
  LinkIcon,
  LinkStyled,
  LinkText,
  LinkTextNoMargin,
  MissionText,
  QrCode,
  SpanStyled,
  VerticalSeparator,
} from "./styles";

export default function FooterContent() {
  let year = new Date().getFullYear();
  // Passing tracking UTM params from v3 employer landing page to v2 employer sign up page
  let urlParams = document.cookie
    .split("; ")
    .find((row) => row.startsWith("params="))
    ?.toString()
    .replace("params=", "");

  return (
    <ContainerStyled>
      <ContentContainer>
        <FirstContentContainer>
          <ColumnContainer section="mission">
            <GridLinksContainer>
              <CompanyTitleStyled>hiredly</CompanyTitleStyled>
              <div>
                <GridMission>
                  <MissionText>
                    Our vision is to be SEA's most personalised talent
                    ecosystem; elevating human progress by helping careers and
                    companies grow.
                  </MissionText>
                </GridMission>
              </div>
            </GridLinksContainer>
          </ColumnContainer>

          <ColumnContainerMiddle>
            <ColumnLink>
              <CompanyTitleStyled>hiredly</CompanyTitleStyled>
              <GridLinks>
                <NextLink href="/about-us" legacyBehavior>
                  <LinkText id={"footer-about-us-button"}>about us</LinkText>
                </NextLink>
                <NextLink href="/contact-us" legacyBehavior>
                  <LinkText id={"footer-contact-us-button"}>
                    contact us
                  </LinkText>
                </NextLink>
                <NextLink href="/newsroom" legacyBehavior>
                  <LinkText id={"footer-newsroom-button"}>newsroom</LinkText>
                </NextLink>
                <NextLink href="/faq" legacyBehavior>
                  <LinkText id={"footer-faq-button"}>FAQ</LinkText>
                </NextLink>
                <NextLink href="/company/hiredly-x" legacyBehavior>
                  <LinkText id={"hiredly-x-button"}>Hiredly X</LinkText>
                </NextLink>
                <NextLink href="/updates" legacyBehavior>
                  <LinkText id={"footer-updates-button"}>Updates</LinkText>
                </NextLink>
              </GridLinks>
            </ColumnLink>
            <ColumnLink>
              <CompanyTitleStyled>jobseeker</CompanyTitleStyled>
              <GridLinks>
                <NextLink href="/sitemap/jobs-by-specialisation" legacyBehavior>
                  <LinkText id={"footer-jobs-by-specialisation-button"}>
                    jobs <SpanStyled>by</SpanStyled> specialisation
                  </LinkText>
                </NextLink>
                <NextLink href="/sitemap/jobs-by-location" legacyBehavior>
                  <LinkText id={"footer-jobs-by-state-button"}>
                    jobs <SpanStyled>by</SpanStyled> location
                  </LinkText>
                </NextLink>
                <NextLink href="/sitemap/jobs-by-type" legacyBehavior>
                  <LinkText id={"footer-jobs-by-type-button"}>
                    jobs <SpanStyled>by</SpanStyled> type
                  </LinkText>
                </NextLink>
                <NextLink
                  href="/sitemap/jobs-by-experience-level"
                  legacyBehavior
                >
                  <LinkText id={"footer-jobs-by-experience-level-button"}>
                    jobs <SpanStyled>by</SpanStyled> experience level
                  </LinkText>
                </NextLink>
                <NextLink href="/companies" legacyBehavior>
                  <LinkText id={"footer-companies-button"}>companies</LinkText>
                </NextLink>
                <NextLink href="/advice" legacyBehavior>
                  <LinkText id={"footer-advice-button"}>advice</LinkText>
                </NextLink>
              </GridLinks>
            </ColumnLink>
            <ColumnLink>
              <CompanyTitleStyled>employer</CompanyTitleStyled>
              <GridLinks>
                <LinkExternal href={setEmployerParams()}>
                  <LinkText id={"footer-start-hiring-button"}>
                    start hiring today
                  </LinkText>
                </LinkExternal>
                <LinkExternal href="https://x.hiredly.com/employer">
                  <LinkText id={"footer-headhunting-button"}>
                    headhunting service
                  </LinkText>
                </LinkExternal>
              </GridLinks>
            </ColumnLink>
          </ColumnContainerMiddle>
        </FirstContentContainer>

        <ColumnContainer section="download_app_logo">
          <LinkStyled
            href="https://itunes.apple.com/us/app/wobb/id981491669?mt=8"
            rel="noopener"
            target="_blank"
          >
            <DownloadAppLogo
              src={
                config.assetDomain +
                "/images/hiredly/app_store_download_button.svg"
              }
              alt="app_store_download_button"
            />
          </LinkStyled>
          <LinkStyled
            href="https://play.google.com/store/apps/details?id=com.wobb.hunted&hl=en"
            rel="noopener"
            target="_blank"
          >
            <DownloadAppLogo
              src={
                config.assetDomain +
                "/images/hiredly/google_play_download_button.svg"
              }
              alt="google_play_download_button"
            />
          </LinkStyled>
          <LinkStyled
            href="https://appgallery.huawei.com/#/app/C101027941?channelId=homepage-footer&id=8c375cc9194d469488f3f163394cfbd5&s=EDFF426DF95D1FCBCCE03918FB64DFF9C7197D34B481DE8A2BE8654FDF391EFB&detailType=0&v="
            rel="noopener"
            target="_blank"
          >
            <DownloadAppLogo
              src={
                config.assetDomain +
                "/images/hiredly/app_gallery_download_button.svg"
              }
              alt="app_gallery_download_button"
            />
          </LinkStyled>
          <QrCode
            src={
              config.assetDomain + "/images/landing/onelink-app-download-qr.png"
            }
            alt="app_gallery_download_button"
          />
        </ColumnContainer>
      </ContentContainer>

      <CopyrightContainer>
        <GridIconsContainer>
          <GridIcons>
            <LinkIcon
              id={"footer-facebook-button"}
              href="https://www.facebook.com/hiredly"
              rel="noopener"
              target="_blank"
            >
              <IconStyled icon={faFacebook} />
            </LinkIcon>
            <LinkIcon
              id={"footer-instagram-button"}
              href="https://www.instagram.com/growwithhiredly/"
              rel="noopener"
              target="_blank"
            >
              <IconStyled icon={faInstagram} />
            </LinkIcon>
            <LinkIcon
              id={"footer-tiktok-button"}
              href="https://www.tiktok.com/@growwithhiredly "
              rel="noopener"
              target="_blank"
            >
              <IconStyled icon={faTiktok} />
            </LinkIcon>
            <LinkIcon
              id={"footer-linkedin-button"}
              href="https://www.linkedin.com/company/hiredlymy/"
              rel="noopener"
              target="_blank"
            >
              <IconStyled icon={faLinkedinIn} />
            </LinkIcon>
            <LinkIcon
              id={"footer-spotify-button"}
              href="https://open.spotify.com/user/hjpf36a3ey64jq35xxx2d2rzd?si=441f177d08d74926"
              rel="noopener"
              target="_blank"
            >
              <IconStyled icon={faSpotify} />
            </LinkIcon>
            <LinkIcon
              id={"footer-youtube-button"}
              href="https://www.youtube.com/hiredly"
              rel="noopener"
              target="_blank"
            >
              <IconStyled icon={faYoutube} />
            </LinkIcon>
          </GridIcons>
        </GridIconsContainer>

        <CopyrightText>
          {`© Copyright 2014 - ${year} Agensi Pekerjaan Wobb Sdn. Bhd.`}
        </CopyrightText>

        <CopyrightContainerMiddle>
          <NextLink href="/community-guidelines" legacyBehavior>
            <LinkTextNoMargin id={"footer-community-guidelines-button"}>
              Community Guidelines
            </LinkTextNoMargin>
          </NextLink>
          <VerticalSeparator></VerticalSeparator>
          <NextLink href="/privacy-policy" legacyBehavior>
            <LinkTextNoMargin id={"footer-privacy-policy-button"}>
              Privacy Policy
            </LinkTextNoMargin>
          </NextLink>
          <VerticalSeparator></VerticalSeparator>
          <NextLink href="/terms-and-conditions" legacyBehavior>
            <LinkTextNoMargin id={"footer-terms-and-conditions-button"}>
              Terms & Conditions
            </LinkTextNoMargin>
          </NextLink>
          <VerticalSeparator></VerticalSeparator>
          <NextLink href="/sitemap" legacyBehavior>
            <LinkTextNoMargin id={"footer-sitemap-button"}>
              Site Map
            </LinkTextNoMargin>
          </NextLink>
        </CopyrightContainerMiddle>
      </CopyrightContainer>
    </ContainerStyled>
  );
}
